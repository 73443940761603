.app-page-view {
    transition: background-color 1s ease;

    &.watch {
        background-color: #000;

        .app-header {
            background-color: #080808;

            .logo-container img {
                max-width: 100px;
                max-height: 50px;
                opacity: .3;

                &:hover {
                    opacity: 1;
                }
            }

            .back-button {
                opacity: .2;

                &:hover {
                    opacity: .3;
                }
            }
        }
    }
}