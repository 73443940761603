.app-header {
    $padding: 20px;

    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: calc(100% - #{$padding * 2});
    height: 64px;
    line-height: 0;
    padding: 0 $padding;
    background-color: #282828;
    animation: scroll-from-top .2s ease-in-out 1;
    transition: all 1s ease;

    .back-button {
        cursor: pointer;
        opacity: .5;
        transition: opacity .2s ease;
        padding: 20px;
        margin-left: -20px;
        display: inline-block;
        vertical-align: middle;

        svg {
            fill: #eee;
            transform: rotate(90deg);
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            opacity: 1;
        }
    }

    .logo-container {
        position: absolute;
        left: 50%;
        top: 20px;
        transform: translate(-50%, 0);
        animation: scroll-from-top .5s cubic-bezier(0.390, 0.100, 0.000, 1.650) 1;

        img {
            max-width: 200px;
            max-height: 100px;
            object-fit: contain;
            transition: all .5s ease;
        }
    }
}

@keyframes scroll-from-top {
    from {
        transform: translate(-50%,-100%);
    }
    to {
        transform: translate(-50%,0);
    }
}