.spinner {
    .circle {
        $circle-count: 60;
        $circle-diameter: 0.5em;
        $ring-radius: 4vw;
        $duration: 1s;
        position: absolute;
        width: $circle-diameter;
        height: $circle-diameter;
        border-radius: 50%;
        background: #23648a;
        opacity: 0;
        animation: move-to-center $duration ease-in-out infinite;
        animation-direction: reverse;

        @for $i from 1 through $circle-count {
            &:nth-child(#{$i}) {
                $ratio: $i / $circle-count;
                // Make a ring of circles, and move them to center;
                transform: rotate($ratio * 720deg) translateX($ring-radius);
                // Then stagger the animation, and it becomes a spiral.
                animation-delay: $ratio * $duration;
            }
        }
    }
}

@keyframes move-to-center {
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}