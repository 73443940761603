@function pi() {
    @return 3.14159265359;
}

@function pow($number, $exp) {
    $value: 1;
    @if $exp > 0 {
        @for $i from 1 through $exp {
        $value: $value * $number;
        }
    } @else if $exp < 0 {
        @for $i from 1 through -$exp {
        $value: $value / $number;
        }
    }
    @return $value;
}

@function rad($angle) {
    $unitless: $angle * pi() / 180;
    @return $unitless;
}

@function fact($number) {
    $value: 1;
    @if $number > 0 {
        @for $i from 1 through $number {
        $value: $value * $i;
        }
    }
    @return $value;
}

@function sin($angle) {
    $sin: 0;
    $angle: rad($angle);
    // Iterate a bunch of times.
    @for $i from 0 through 10 {
        $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
    }
    @return $sin;
}

@function cos($angle) {
    $cos: 0;
    $angle: rad($angle);
    // Iterate a bunch of times.
    @for $i from 0 through 10 {
        $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
    }
    @return $cos;
}

.logo-selector {
    $count: 12;

    li {
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        transition: transform .5s ease-in-out, opacity .5s ease;
        transform: translate(-50%,-50%) scale(0);
        opacity: 0;

        & > a {
            pointer-events: none;
        }

        &.logo-current {
            transform: translate(-50%,-50%) scale(1);
            opacity: .9;
            z-index: 12;

            & > a {
                pointer-events: all;
            }
        }

        
        @for $i from 1 through ( $count / 2 ) {
            $mod: $i * .1;
            $a: $i * ( 360 / $count / 2);
            $y: sin( $a );
            $z: cos( $a );
            $scale: cos( $a ) * .75;

            &.logo-next-#{$i} {
                transform: translate(-50%,-50%) perspective(400px) translate3D( 0, $y * 75vh, $z * 400px - 500px ) rotateX( -$a * 1deg ) scale(.9);
                opacity: .5 - $mod;
                z-index: 12 - $i;
                transition-duration: .5s + $i * .1s;

                img {
                    animation-duration: $i * .25s + .5s;
                }
            }

            &.logo-prev-#{$i} {
                transform: translate(-50%,-50%) perspective(400px) translate3D( 0, -$y * 75vh, $z * 400px - 500px ) rotateX( $a * 1deg ) scale(.9);
                opacity: .5 - $mod;
                z-index: 12 - $i;
                transition-duration: .5s + $i * .1s;
            }
        }

        &:hover {
            opacity: 1;
        }

        img {
            animation: scale-in .5s cubic-bezier(0.390, 0.100, 0.000, 1.650) 1;
        }
    }
}

@keyframes scale-in {
    from {
        transform: scale(0,0);
    }

    to {
        transform: scale(1,1);
    }
}