.series-container {
    .series {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        li {
            $max-count: 24;

            flex: 0 0 25%;
            padding: 20px;
            box-sizing: border-box;
            overflow: hidden;
            transform: scale(1);
            transition: transform .5s ease;
            animation: scale-in .5s cubic-bezier(0.390, 0.100, 0.000, 1.650) 1;

            
            @for $i from 1 through $max-count {
                &:nth-child(#{$i}) {
                    animation-duration: .3s + $i * .2s;
                }
            }

            &:hover {
                transform: scale(1.1);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            a {
                text-decoration: none;
            }

            .no-illustration {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-content: center;
                background-color: #282828;
                width: 100%;
                height: 100%;
                text-align: center;
                text-decoration: none;
                color: #444;
            }
        }
    }
}