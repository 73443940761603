.aine-player {
    width: 70%;
    height: 70%;
    position: relative;

    .view {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);

        &.double {
            opacity: 0;
            transition: opacity .5s ease;

            &.visible {
                opacity: 1;
            }
        }
    }

    .play-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100px;
        height: 100px;
        background-color: rgba( 190, 190, 190, .5 );
        border-radius: 50%;
        line-height: 100px;
        text-align: center;
        cursor: pointer;
        transition: opacity .2s ease, transform .2s ease;
        z-index: 100500;

        &:hover {
            transform: translate(-50%,-50%) scale(1.1);
        }

        &.hidden {
            opacity: 0;
        }
    }

    .text-popup {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        background-color: rgba(0,0,0,.9);
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: opacity .5s ease;
        pointer-events: none;
        max-width: 700px;
        width: 100%;

        &.no-portrait {
            aside,
            h3 {
                display: none;
            }
        }

        aside {
            flex: 0 0 64px;
            margin-right: 20px;

            img {
                width: 64px;
                height: 64px;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: bold;
        }

        main {
            font-size: 16px;
            font-weight: normal;
            flex: 1 1 auto;
            max-width: calc(100% - 124px);
        }
    }
}